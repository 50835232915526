import * as React from "react"

const NotFoundPage = () => {
    return (
        <main>
            <h1>404</h1>
        </main>
    )
}

export default NotFoundPage
